import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import showdown from 'showdown'

const Content = ({ content, district }) => {
const converter = new showdown.Converter({
    strikethrough: true
})
let slugTable = {
    "1": "hwcid-1",
    "2": "hwcid-2",
}
return (
    <>
        { content.map(ent => {
            switch (ent.__typename) {
                case "GRAPHAPI_ComponentPageBaseContent":

                    let body = converter.makeHtml(ent.body)
                    return (<div className="content-align" style={{minHeight: ent.minHeight}}key={ent.id} dangerouslySetInnerHTML={{__html: body}}></div>)
                    break
                case "GRAPHAPI_ComponentPageImage":
                    console.log(ent)
                    if(ent.placement === "banner") {
                        return (<Img className="banner" key={ent.id} fluid={ent.image.documentFile.childImageSharp.fluid} />)
                    }else if(ent.placement === "wide") {
                        return (<Img className="wide" key={ent.id} fluid={ent.image.documentFile.childImageSharp.fluid} />)
                    }else if(ent.placement === "left") {
                        return (<div className="content-align"><Img key={ent.id} style={{ float: 'left', marginRight: '1rem', width: '50%', maxWidth: ent.width ?? ''}} fluid={ent.image.documentFile.childImageSharp.fluid} /></div>)
                    }else if(ent.placement === "right") {
                        return (<div className="content-align"><Img key={ent.id} style={{ float: 'right', marginLeft: '1rem', width: '50%', maxWidth: ent.width ?? ''}} fluid={ent.image.documentFile.childImageSharp.fluid} /></div>)
                    } else {
                        return (<div className="content-align"><Img key={ent.id} style={{ maxWidth: ent.width ?? ''}} fluid={ent.image.documentFile.childImageSharp.fluid} /></div>)
                    }
                    break
                case "GRAPHAPI_ComponentPageLinkCardWithImage":
                    
                    let cardbody = converter.makeHtml(ent.linkbody)
                    return (
                        <div className="content-align">
                            <a className="box link-card" href={ent.url} target="_blank" rel="noopener noreferrer">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className="link-card-image link-card-image-square">
                                            <Img objectFit="contain" fluid={ent.image.documentFile.childImageSharp.fluid} alt="" />
                                        </figure>
                                    </div>
                                    <div class="media-content" dangerouslySetInnerHTML={{__html: cardbody}}></div>
                                </div>
                            </a>
                        </div>
                    )
                    break
                case "GRAPHAPI_ComponentPageSplitContentImage":
                    let splitfixedLinks = ent.splitbody.replace(/{PREFIX}/g, `/${slugTable[district]}`)
                    let splitbody = converter.makeHtml(splitfixedLinks)
                    return (
                        <div class="split-w-image">
                            <div className="split">
                                <div className="content-align" key={ent.id} dangerouslySetInnerHTML={{__html: splitbody}}></div>
                                    { ent.linkList !== null ? (
                                        <div className="content-align link-list">
                                            {ent.linkList.map(tlink => {
                                                if(tlink.type === "file") {
                                                    return (<a key={tlink.id} href={tlink.document.documentFile.publicURL} className="button is-primary">{tlink.title}</a>)
                                                } else if(tlink.type === "url") {
                                                    return (<a key={tlink.id} href={tlink.url} className="button is-primary">{tlink.title}</a>)
                                                }
                                            })}

                                        </div>
                                    ) : (<></>) }
                            </div>
                            <div className="split">
                                <div className="image-container">
                                    <Img fluid={ent.image.documentFile.childImageSharp.fluid} alt="" />
                                </div>
                            </div>
                        </div>
                    )
                    break
                case "GRAPHAPI_ComponentPageGallery":
                    return (
                        <div class="gallery">
                            {ent.images.map(image => (
                                <>
                                { image.caption !== '' ? (
                                    <div key={image.id} className="gallery-item">
                                        <Img fluid={image.documentFile.childImageSharp.fluid} alt="" />
                                        <div className="gallery-item-caption">
                                        <h4 className="is-size-3">{image.alternativeText}</h4>
                                        <p className="">{image.caption}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={image.id} className="gallery-item no-caption">
                                        <Img fluid={image.documentFile.childImageSharp.fluid} alt="" />
                                    </div>
                                )}
                                </>
                            ))}
                        </div>
                    )
                    break
                case "GRAPHAPI_ComponentPageLinkList":
                    return (
                        <div className="content-align link-list">
                            {ent.link.map(tlink => {
                                if(tlink.type === "file") {
                                    return (<a key={tlink.id} href={tlink.document.documentFile.publicURL} className="button is-primary">{tlink.title}</a>)
                                } else if(tlink.type === "url") {
                                    return (<a key={tlink.id} href={tlink.url} className="button is-primary">{tlink.title}</a>)
                                }
                            })}

                        </div>
                    )
                    break
                default:
                    return (<p key={ent.id}>{ent.id}</p>)
            }
        })}
    </>
)
}

export default Content